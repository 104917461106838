import React, { useState } from "react";

const HowToPlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Question Icon */}
      <button
        onClick={togglePopup}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-center justify-center h-full w-full"
      >
        <img
          src="/circle-question-solid.svg"
          alt="How to Play"
          className={`h-8 w-8 cursor-pointer ${
            isHovered ? "animate-spinOnce" : "animate-spinReverse"
          }`}
        />
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center text-left items-center z-50 overflow-auto">
          <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-lg w-full text-white relative overflow-auto max-h-[80vh] m-4">
            {/* Header */}
            <h2 className="text-3xl font-bold mb-4">How to Play</h2>
            <p className="mb-6">Welcome to the game! Here's everything you need to know:</p>

            {/* Instructions */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Objective</h3>
              <p className="mb-4">
                The goal is to guess the daily player by analyzing various categories like career points, position, team, and more.
              </p>

              <h3 className="text-xl font-semibold mb-2">How to Play</h3>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Start typing a player's name in the input box.</li>
                <li>Select the player from the dropdown list.</li>
                <li>Compare their stats to the daily player.</li>
                <li>Use the arrows and colors as clues to refine your guesses.</li>
                <li>Keep guessing until you find the correct player!</li>
              </ul>

              <p className="mb-4">
                Arrows will guide you:
                <span className="block mt-2">⬆️ Higher than your guess</span>
                <span>⬇️ Lower than your guess</span>
              </p>
            </div>

            {/* Categories and Colors */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Categories Explained</h3>
              <ul className="space-y-4">
                <li>
                  <strong>Team:</strong> The player's current team.
                </li>
                <li>
                  <strong>Position:</strong> Can be C (Center), L (Left Wing), R (Right Wing), D (Defenseman), or G (Goalie).
                </li>
                <li>
                  <strong>Conference:</strong> The player's conference (e.g., Atlantic, Pacific, Central or Metropolitan).
                </li>
                <li>
                  <strong>Birth Country:</strong> The country where the player was born.
                </li>
                <li>
                  <strong>Career Points:</strong> Total points scored in the player's career (For goalies, this might be 0).
                </li>
                <li>
                  <strong>Overall Pick:</strong> The player's overall draft pick number.
                </li>
                <li>
                  <strong>Age:</strong> The player's age in years.
                </li>
                <li>
                  <strong>Sweater Number:</strong> The player's jersey number.
                </li>
              </ul>
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Colors & What They Mean</h3>
              <ul className="space-y-4">
                <li>
                  <span className="bg-green-500 text-black px-2 py-1 rounded">Green:</span> Exact match.
                </li>
                <li>
                  <span className="bg-yellow-500 text-black px-2 py-1 rounded">Yellow:</span> Close match (e.g., within 5 units).
                </li>
                <li>
                  <span className="bg-red-500 text-white px-2 py-1 rounded">Red:</span> Incorrect value.
                </li>
                <li>
                  <span className="bg-gray-500 text-white px-2 py-1 rounded">Gray:</span> Missing or not applicable.
                </li>
              </ul>
            </div>

            {/* Example Section */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Example</h3>
              <p>
                You guess <strong>Player A</strong>, who has a sweater number of 10. If the daily player's number is 15:
              </p>
              <ul className="list-disc list-inside mt-2 space-y-2">
                <li><span className="bg-yellow-500 text-black px-2 py-1 rounded">Yellow</span>: Close match (within 5).</li>
                <li><span className="bg-green-500 text-black px-2 py-1 rounded">Green</span>: If you guess 15.</li>
              </ul>
            </div>

            {/* Data Attribution */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Data Attribution</h3>
              <p className="mb-4">
                All player data is sourced from the{" "}
                <a
                  href="https://www.nhl.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  official NHL API
                </a>
                . For more information, visit the{" "}
                <a
                  href="https://www.nhl.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-500 underline"
                >
                  NHL website
                </a>
                .
              </p>
            </div>

            {/* Internal Links for SEO */}
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Explore More</h3>
              <ul className="list-disc list-inside mb-4 space-y-2">
                <li>
                  <a
                    href="/classic"
                    className="text-blue-400 hover:text-blue-500 underline"
                  >
                    Classic Game Mode
                  </a>
                </li>
                <li>
                  <a
                    href="/roster"
                    className="text-blue-400 hover:text-blue-500 underline"
                  >
                    Roster Guess Game
                  </a>
                </li>
              </ul>
            </div>

            {/* Close Button */}
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <button
              onClick={togglePopup}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Got It!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HowToPlay;
