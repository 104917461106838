import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const GameNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get current route path

  const games = [
    { id: "classic", name: "Classic Game", img: "player.png", imgGold: "player_gold.png" },
    { id: "unlimited", name: "Unlimited Game", img: "roster.png", imgGold: "roster_gold.png" }, // Updated name
  ];

  const handleGameClick = (gameId) => {
    navigate(`/${gameId}`); // Navigate to the selected game
  };

  return (
    <div className="flex justify-center mt-4">
      <div className="flex gap-6 p-2 rounded-lg shadow-lg border-white border-s border-e bg-info-content bg-opacity-40">
        {games.map((game) => (
          <div
            key={game.id}
            className="text-center"
            onClick={() => handleGameClick(game.id)}
          >
            <img
              src={`/menu/${
                location.pathname.includes(game.id) ? game.imgGold : game.img
              }`}
              alt={game.name}
              className="w-12 h-12 cursor-pointer hover:scale-110 transition-transform mx-auto"
            />
            <p className="text-white text-sm font-bold mt-1">{game.name}</p> {/* Game name */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameNavigation;
