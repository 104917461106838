import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import GameNavigation from "../components/GameNavigation";

const RosterGuessGame = () => {
  const [roster, setRoster] = useState([]);
  const [team, setTeam] = useState("");
  const [year, setYear] = useState("");
  const [hiddenPlayers, setHiddenPlayers] = useState([]);
  const [guess, setGuess] = useState("");
  const [feedback, setFeedback] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [gameWon, setGameWon] = useState(false);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("rosterState"));

    // Get current date and compare with saved state
    const currentDate = new Date().toISOString().split("T")[0]; // Format: YYYY-MM-DD

    if (savedState && savedState.date === currentDate) {
      // If the saved state is from today, load it
      setRoster(savedState.roster || []);
      setTeam(savedState.team || "");
      setYear(savedState.year || "");
      setHiddenPlayers(savedState.hiddenPlayers || []);
      setGameWon(savedState.gameWon || false);
      setFeedback(
        savedState.gameWon
          ? `Correct! 🎉 You guessed the team ${savedState.team.toUpperCase()}!`
          : ""
      );
    } else {
      // Fetch a new roster if it's a new day
      fetchRoster();
    }
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/nhl-teams`);
        const data = await response.json();

        if (data.success) {
          setTeamList(data.teams);
        }
      } catch (error) {
        console.error("Error fetching team list:", error);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    if (roster.length > 0) {
      localStorage.setItem(
        "rosterState",
        JSON.stringify({
          roster,
          team,
          year,
          hiddenPlayers,
          gameWon,
          date: new Date().toISOString().split("T")[0],
        })
      );
    }
  }, [roster, team, year, hiddenPlayers, gameWon]);

  const fetchRoster = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/daily-roster`);
      const data = await response.json();

      if (data.success) {
        setRoster(data.roster);
        setTeam(data.team_abbreviation.toUpperCase());
        setYear(data.year || "Unknown Year");

        const hidden = Array(data.roster.length).fill(true);
        for (let i = 0; i < 3 && i < data.roster.length; i++) {
          hidden[i] = false;
        }
        setHiddenPlayers(hidden);

        localStorage.setItem(
          "rosterState",
          JSON.stringify({
            roster: data.roster,
            team: data.team_abbreviation,
            year: data.year,
            hiddenPlayers: hidden,
            gameWon: false,
            date: new Date().toISOString().split("T")[0],
          })
        );
      }
    } catch (error) {
      console.error("Error fetching roster:", error);
    }
  };

  const handleGuess = () => {
    if (gameWon) {
      setFeedback(`You've already guessed correctly!`);
      return;
    }

    if (!guess) {
      setFeedback("Please select a team.");
      return;
    }

    if (guess.toUpperCase() === team.toUpperCase()) {
      setFeedback(`Correct! 🎉 You guessed the team ${team.toUpperCase()}!`);
      setGameWon(true);
    } else {
      setFeedback("Incorrect. Try again!");

      const updatedHidden = [...hiddenPlayers];
      let revealCount = 0;

      for (let i = 0; i < updatedHidden.length; i++) {
        if (updatedHidden[i] && revealCount < 2) {
          updatedHidden[i] = false;
          revealCount++;
        }
      }

      setHiddenPlayers(updatedHidden);
    }

    setGuess("");
  };

  return (
    <div className="fixed inset-0 bg-cover bg-center">
      <Header />
      <GameNavigation />
      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-2 pb-56 mt-5">
        <div className="text-center">
          {!gameWon && (
            <div className="mt-8 sm:w-4/6 mx-auto flex flex-row justify-center items-center gap-4">
              <select
                value={guess}
                onChange={(e) => setGuess(e.target.value)}
                className="select select-bordered flex-1 text-black"
              >
                <option value="" disabled>
                  Select a team
                </option>
                {teamList.map((team) => (
                  <option key={team.abbreviation} value={team.abbreviation}>
                    {team.team_name}
                  </option>
                ))}
              </select>

              <button
                onClick={handleGuess}
                className="btn btn-primary flex-shrink-0"
              >
                Submit
              </button>
            </div>
          )}
          <div
            role="alert"
            className="alert alert-info p-2 text-sm max-w-xs mx-auto my-4"
          >
            <span className="ml-2">{feedback}</span>
          </div>
        </div>
        <div className="overflow-x-auto flex sm:justify-center">
          <table className="table-auto w-max border border-gray-700 rounded-lg">
            <thead className="bg-gray-800 text-white">
              <tr>
                <th className="py-3 px-4 text-left">#</th>
                <th className="py-3 px-4 text-left">Player</th>
                <th className="py-3 px-4 text-left">Position</th>
                <th className="py-3 px-4 text-left">Goals</th>
                <th className="py-3 px-4 text-left">Assists</th>
                <th className="py-3 px-4 text-left">Points</th>
              </tr>
            </thead>
            <tbody className="bg-gray-900 text-gray-300">
              {roster.map((player, index) => {
                if (!hiddenPlayers[index]) {
                  return (
                    <tr
                      key={index}
                      className="hover:bg-gray-700 transition-colors border-t border-gray-700"
                    >
                      <td className="py-3 px-4">{index + 1}</td>
                      <td className="py-3 px-4 flex items-center gap-4">
                        <img
                          src={player.headshot}
                          alt={player.name}
                          className="w-10 h-10 rounded-full"
                        />
                        <span>{player.fullName}</span>
                      </td>
                      <td className="py-3 px-4">{player.position}</td>
                      <td className="py-3 px-4">{player.goals}</td>
                      <td className="py-3 px-4">{player.assists}</td>
                      <td className="py-3 px-4">{player.points}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RosterGuessGame;
