import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import Confetti from "react-confetti";
import Header from "../components/Header";
import HowToPlay from "../components/HowToPlay";
import Updates from "../components/Updates";
import About from "../components/About";
import Stats from "../components/Stats";
import GameNavigation from "../components/GameNavigation";

const ClassicGame = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [guess, setGuess] = useState("");
  const [dailyPlayer, setDailyPlayer] = useState(null);
  const [yesterdayPlayer, setYesterdayPlayer] = useState(null);
  const [gameWon, setGameWon] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [streak, setStreak] = useState(0); // User's streak count
  const [arrowSrc, setArrowSrc] = useState("/arrow-right.png");
  const [stats, setStats] = useState({
    totalWins: 0,
    totalGames: 0,
    currentStreak: 0,
    maxStreak: 0,
    avgGuesses: 0,
  });
  const [totalGuesses, setTotalGuesses] = useState(0);
  const [yesterdayGuesses, setYesterdayGuesses] = useState(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // Progress state
  const [dataLoaded, setDataLoaded] = useState(false); // Tracks if data is loaded

  const cardRef = useRef(null);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:5001";

  const sessionId = localStorage.getItem("session_id");

  useEffect(() => {
    // Check if the page has already been refreshed
    const hasRefreshed = sessionStorage.getItem("hasRefreshed");

    if (!hasRefreshed) {
      // Set the flag in sessionStorage
      sessionStorage.setItem("hasRefreshed", "true");

      // Reload the page
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const fetchGuesses = async () => {
      const today = new Date().toISOString().split("T")[0];
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const yesterdayStr = yesterday.toISOString().split("T")[0];

      try {
        // Fetch today's guesses
        const todayResponse = await fetch(`${API_BASE_URL}/api/total-guesses`);
        if (!todayResponse.ok) {
          throw new Error("Failed to fetch today's guesses.");
        }
        const todayData = await todayResponse.json();
        setTotalGuesses(todayData.totalGuesses || 0);

        // Fetch yesterday's guesses
        const yesterdayResponse = await fetch(
          `${API_BASE_URL}/api/total-guesses-date?date=${yesterdayStr}`
        );
        if (!yesterdayResponse.ok) {
          throw new Error("Failed to fetch yesterday's guesses.");
        }
        const yesterdayData = await yesterdayResponse.json();
        setYesterdayGuesses(yesterdayData.correctGuesses || 0);
      } catch (error) {
        console.error("Error fetching guesses:", error);
      }
    };

    // Fetch guesses initially
    fetchGuesses();

    // Set up polling for today's total guesses
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/total-guesses`);
        if (!response.ok) {
          throw new Error("Failed to fetch total guesses.");
        }
        const data = await response.json();
        setTotalGuesses(data.totalGuesses || 0);
      } catch (error) {
        console.error("Error fetching total guesses:", error);
      }
    }, 10000); // Poll every 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const calculateStats = () => {
      const savedWins = parseInt(localStorage.getItem("totalWins") || "0", 10);
      const savedGames = parseInt(
        localStorage.getItem("totalGames") || "0",
        10
      );
      const savedMaxStreak = parseInt(
        localStorage.getItem("maxStreak") || "0",
        10
      );
      const savedCurrentStreak = parseInt(
        localStorage.getItem("streak") || "0",
        10
      );
      const savedGuesses = JSON.parse(
        localStorage.getItem("guessCounts") || "[]"
      );

      const totalGuesses = savedGuesses.reduce((sum, count) => sum + count, 0);
      const avgGuesses =
        savedGames > 0 ? (totalGuesses / savedGames).toFixed(2) : 0;

      setStats({
        totalWins: savedWins,
        totalGames: savedGames,
        currentStreak: savedCurrentStreak,
        maxStreak: savedMaxStreak,
        avgGuesses: avgGuesses,
      });
    };

    calculateStats();
  }, [gameWon]); // Recalculate stats when game is won

  // Calculate time left until midnight
  const calculateTimeLeft = () => {
    const now = DateTime.utc(); // Current time in UTC
    const midnightUTC = now.endOf("day"); // End of the current day in UTC
    const difference = midnightUTC.diff(now, ["hours", "minutes", "seconds"]);

    return {
      hours: difference.hours,
      minutes: difference.minutes,
      seconds: Math.floor(difference.seconds),
    };
  };

  useEffect(() => {
    const savedGuesses = JSON.parse(localStorage.getItem("guesses")) || [];
    const savedGameWon = localStorage.getItem("gameWon") === "true";

    setGuesses(savedGuesses);
    if (savedGameWon) {
      setGameWon(true);
    }
  }, []);

  // Streak management
  useEffect(() => {
    // Initialize streak from localStorage
    const savedStreak = parseInt(localStorage.getItem("streak") || "0", 10);
    setStreak(savedStreak);
  }, []);

  // Handle streak management
  useEffect(() => {
    if (gameWon) {
      const today = new Date().toISOString().split("T")[0];
      const lastPlayedDate = localStorage.getItem("lastPlayedDate");
      let currentStreak = parseInt(localStorage.getItem("streak") || "0", 10);

      console.log("Today:", today);
      console.log("Last Played Date:", lastPlayedDate);
      console.log("Current Streak Before Update:", currentStreak);

      // If this is the first win of the day
      if (lastPlayedDate === today && currentStreak === 0) {
        currentStreak = 1; // Initialize streak for the first win
        console.log("Streak initialized to:", currentStreak);
      } else if (!lastPlayedDate || lastPlayedDate !== today) {
        // Check if the last played date is yesterday to increment streak
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayString = yesterday.toISOString().split("T")[0];

        if (lastPlayedDate === yesterdayString) {
          currentStreak += 1; // Increment streak
          console.log("Incrementing streak:", currentStreak);
        } else {
          currentStreak = 1; // Reset streak to 1
          console.log("Resetting streak to:", currentStreak);
        }

        localStorage.setItem("lastPlayedDate", today);
      } else {
        console.log("Streak already updated for today.");
      }

      localStorage.setItem("streak", currentStreak);
      setStreak(currentStreak);
      console.log("Updated Streak:", currentStreak);
    }
  }, [gameWon]);

  const getConference = (teamAbbrev) => {
    const atlantic = ["BOS", "BUF", "DET", "FLA", "MTL", "OTT", "TBL", "TOR"];
    const metropolitan = [
      "CAR",
      "CBJ",
      "NJD",
      "NYI",
      "NYR",
      "PHI",
      "PIT",
      "WSH",
    ];
    const central = ["CHI", "COL", "DAL", "MIN", "NSH", "STL", "WPG", "UTA"];
    const pacific = ["ANA", "CGY", "EDM", "LAK", "SEA", "SJS", "VAN", "VGK"];

    if (atlantic.includes(teamAbbrev)) return "atlantic.png";
    if (metropolitan.includes(teamAbbrev)) return "metropolitan.png";
    if (central.includes(teamAbbrev)) return "central.png";
    if (pacific.includes(teamAbbrev)) return "pacific.png";
    return "unknown.png"; // Default image for unknown
  };

  // Save guesses to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("guesses", JSON.stringify(guesses));
  }, [guesses]);

  // Save gameWon state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("gameWon", gameWon);
  }, [gameWon]);

  // Update timer every second
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Reset for the next day
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const lastPlayedDate = localStorage.getItem("lastPlayedDate");

    if (lastPlayedDate !== today) {
      setGuesses([]);
      setGameWon(false);
      localStorage.setItem("lastPlayedDate", today);
      if (dailyPlayer) {
        localStorage.removeItem(
          `guesses_${dailyPlayer.player_id}_${sessionId}`
        );
        localStorage.removeItem(
          `gameWon_${dailyPlayer.player_id}_${sessionId}`
        );
      }
    }
  }, [dailyPlayer]);

  // Fetch all players data
  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/players`);
        if (!response.ok) {
          throw new Error("Failed to fetch players");
        }
        const data = await response.json();

        // Map database fields to include precomputed conference
        const mappedPlayers = data.map((player) => ({
          ...player,
          conference: getConference(player.current_team_abbrev), // Calculate conference here
          fullName: player.full_name,
          teamLogo: player.current_team_logo,
          sweaterNumber: player.sweater_number,
          currentTeam: {
            fullName: player.current_team_full_name,
            abbrev: player.current_team_abbrev,
            logo: player.current_team_logo,
          },
          draftDetails: {
            overallPick: player.draft_overall_pick || 0,
          },
          careerTotals: {
            regularSeason: {
              points: player.career_regular_season_points || 0,
              goals: player.career_regular_season_goals || 0,
              assists: player.career_regular_season_assists || 0,
            },
            playoffs: {
              points: player.career_playoffs_points || 0,
              goals: player.career_playoffs_goals || 0,
              assists: player.career_playoffs_assists || 0,
            },
          },
          birthInfo: {
            date: player.birth_date,
            city: player.birth_city,
            stateProvince: player.birth_state_province,
            country: player.birth_country,
          },
          weight: {
            pounds: player.weight_pounds,
            kilograms: player.weight_kilograms,
          },
          height: {
            inches: player.height_inches,
            centimeters: player.height_centimeters,
          },
          headshot: player.headshot_url,
        }));

        setPlayers(mappedPlayers);
      } catch (error) {
        console.error("Error fetching players:", error.message);
      }
    };
    fetchPlayers();
  }, []);

  useEffect(() => {
    if (dailyPlayer) {
      localStorage.setItem(
        `guesses_${dailyPlayer.player_id}_${sessionId}`,
        JSON.stringify(guesses)
      );
    }
  }, [guesses, dailyPlayer]);

  useEffect(() => {
    if (dailyPlayer) {
      localStorage.setItem(
        `gameWon_${dailyPlayer.player_id}_${sessionId}`,
        gameWon
      );
    }
  }, [gameWon, dailyPlayer]);

  // Fetch the daily player
  useEffect(() => {
    const fetchDailyPlayer = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/daily-player`, {
          headers: { "x-session-id": sessionId },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch daily player");
        }
        const data = await response.json();

        if (!data) {
          console.error("No active daily player found.");
          return;
        }

        setDailyPlayer({
          ...data,
          conference: getConference(data.current_team_abbrev), // Precompute conference
        });

        // Restore guesses for the current daily player
        const savedGuesses =
          JSON.parse(
            localStorage.getItem(`guesses_${data.player_id}_${sessionId}`)
          ) || [];
        setGuesses(savedGuesses);

        // Restore gameWon state
        const savedGameWon = localStorage.getItem("gameWon") === "true";
        if (savedGameWon) {
          setGameWon(true);
        }
      } catch (error) {
        console.error("Error fetching daily player:", error.message);
      } finally {
        setLoading(false); // Mark loading as complete
      }
    };

    const fetchYesterdayPlayer = async () => {
      try {
        // Get yesterday's date in YYYY-MM-DD format
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedDate = yesterday.toISOString().split("T")[0];

        const response = await fetch(
          `${API_BASE_URL}/api/daily-player-date?date=${formattedDate}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch yesterday's player");
        }
        const data = await response.json();

        if (!data) {
          console.error("No player found for yesterday.");
          return;
        }

        setYesterdayPlayer({
          ...data,
          conference: getConference(data.current_team_abbrev), // Precompute conference
        });
      } catch (error) {
        console.error("Error fetching yesterday's player:", error.message);
      }
    };

    fetchDailyPlayer();
    fetchYesterdayPlayer();
  }, []);

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }

    return age;
  };

  const getBackgroundColor = (value, dailyValue, type) => {
    // Normalize "N/A", null, or undefined to 0 for numeric comparisons
    const normalizedValue = value === "N/A" || value == null ? 0 : value;
    const normalizedDailyValue =
      dailyValue === "N/A" || dailyValue == null ? 0 : dailyValue;

    // Check for exact match
    if (normalizedValue === normalizedDailyValue) {
      return "bg-green-500 text-white"; // Exact match
    }

    const isClose = (a, b, range) => Math.abs(a - b) <= range;

    // Handle numbers
    if (type === "number") {
      if (isClose(normalizedValue, normalizedDailyValue, 5)) {
        return "bg-yellow-500 text-black"; // Close match
      } else {
        return "bg-red-500 text-white"; // Incorrect match
      }
    }

    // Handle strings
    if (type === "string") {
      return "bg-red-500 text-white"; // Incorrect string
    }

    // Default for unexpected types
    return "bg-gray-500 text-white";
  };

  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setGuess(input);

    if (input) {
      // Filter players based on the `fullName` property
      const filtered = players
        .filter((player) => player.fullName.toLowerCase().includes(input))
        .slice(0, 20); // Limit results to the top 20
      setFilteredPlayers(filtered);
    } else {
      setFilteredPlayers([]);
    }
  };

  const handleSelectPlayer = (player) => {
    setGuess(player.fullName); // Use the `fullName` property
    setFilteredPlayers([]); // Clear the filtered players list
  };

  const handleCorrectGuess = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/increment-correct-guesses`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-session-id": sessionId, // Include session ID
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to increment correct guesses.");
      }
      console.log("Correct guess counted!");
    } catch (error) {
      console.error("Error counting correct guess:", error);
    }
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault(); // Prevent default behavior

    if (!dailyPlayer) {
      alert("Daily player data is not available yet.");
      return;
    }

    const selectedPlayer = players.find(
      (player) => player.fullName.toLowerCase() === guess.toLowerCase()
    );

    if (!selectedPlayer) {
      alert("Player not found. Please select a valid player.");
      return;
    }

    const guessedAge = calculateAge(selectedPlayer.birthInfo.date);
    const dailyAge = calculateAge(dailyPlayer.birth_date);

    const feedback = {
      name: selectedPlayer.fullName,
      team: selectedPlayer.currentTeam.fullName,
      teamLogo: selectedPlayer.currentTeam.logo,
      sweaterNumber: selectedPlayer.sweaterNumber,
      position: selectedPlayer.position,
      conference: selectedPlayer.conference,
      country: selectedPlayer.birthInfo.country,
      age: guessedAge,
      overallPick: selectedPlayer.draftDetails.overallPick,
      points: selectedPlayer.careerTotals.regularSeason.points,
      headshot: selectedPlayer.headshot,
      teamColor: getBackgroundColor(
        selectedPlayer.currentTeam.fullName,
        dailyPlayer.current_team_full_name,
        "string"
      ),
      sweaterColor: getBackgroundColor(
        selectedPlayer.sweaterNumber,
        dailyPlayer.sweater_number,
        "number"
      ),
      positionColor: getBackgroundColor(
        selectedPlayer.position,
        dailyPlayer.position,
        "string"
      ),
      conferenceColor: getBackgroundColor(
        selectedPlayer.conference,
        dailyPlayer.conference,
        "string"
      ),
      countryColor: getBackgroundColor(
        selectedPlayer.birthInfo.country,
        dailyPlayer.birth_country,
        "string"
      ),
      ageColor: getBackgroundColor(guessedAge, dailyAge, "number"),
      overallPickColor: getBackgroundColor(
        selectedPlayer.draftDetails.overallPick,
        dailyPlayer.draft_overall_pick,
        "number"
      ),
      pointsColor: getBackgroundColor(
        selectedPlayer.careerTotals.regularSeason.points,
        dailyPlayer.career_regular_season_points,
        "number"
      ),
    };

    setGuesses((prev) => [...prev, { ...feedback, visibleCategories: [] }]);

    const revealCategories = [
      "headshot",
      "teamLogo",
      "conference",
      "sweaterNumber",
      "position",
      "country",
      "age",
      "overallPick",
      "careerPoints",
    ];

    revealCategories.forEach((category, index) => {
      setTimeout(() => {
        setGuesses((prev) =>
          prev.map((g, guessIndex) =>
            guessIndex === prev.length - 1
              ? {
                  ...g,
                  visibleCategories: [...g.visibleCategories, category],
                }
              : g
          )
        );
      }, index * 400);
    });

    if (selectedPlayer.fullName === dailyPlayer.full_name) {
      setGameWon(true);
      localStorage.setItem("gameWon", true);
      await handleCorrectGuess(); // Count the correct guess
      // Update stats in localStorage
      const currentWins = parseInt(
        localStorage.getItem("totalWins") || "0",
        10
      );
      const currentGames = parseInt(
        localStorage.getItem("totalGames") || "0",
        10
      );
      const guessCounts = JSON.parse(
        localStorage.getItem("guessCounts") || "[]"
      );

      localStorage.setItem("totalWins", currentWins + 1);
      localStorage.setItem("totalGames", currentGames + 1);
      localStorage.setItem("maxStreak", Math.max(streak, stats.maxStreak));

      // Save the guess count for this game
      guessCounts.push(guesses.length);
      localStorage.setItem("guessCounts", JSON.stringify(guessCounts));
      setConfettiRunning(true);

      setTimeout(() => setConfettiRunning(false), 7000);
      setTimeout(() => {
        cardRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }

    setGuess("");
  };

  // Define the share message
  const generateShareMessage = () => {
    const shareUrl = "https://nhldle.com"; // Replace with your app's URL
    return `🏒🎉 I guessed today's NHL player on nhldle.com! 🏒\nCan you guess it too?\n${shareUrl}`;
  };

  // Copy to clipboard function
  const copyToClipboard = () => {
    const shareMessage = generateShareMessage();
    navigator.clipboard
      .writeText(shareMessage)
      .then(() => {
        alert("Copied to clipboard! Share your win with friends 🎉");
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  const teamLogoMapping = {
    ANA: "anaheim-ducks",
    BOS: "boston-bruins",
    BUF: "buffalo-sabres",
    CAR: "carolina-hurricanes",
    CBJ: "columbus-blue-jackets",
    CGY: "calgary-flames",
    CHI: "chicago-blackhawks",
    COL: "colorado-avalanche",
    DAL: "dallas-stars",
    DET: "detroit-red-wings",
    EDM: "edmonton-oilers",
    FLA: "florida-panthers",
    LAK: "los-angeles-kings",
    MIN: "minnesota-wild",
    MTL: "montreal-canadiens",
    NJD: "new-jersey-devils",
    NSH: "nashville-predators",
    NYI: "new-york-islanders",
    NYR: "new-york-rangers",
    OTT: "ottawa-senators",
    PHI: "philadelphia-flyers",
    PIT: "pittsburgh-penguins",
    SJS: "san-jose-sharks",
    SEA: "seattle-kraken",
    STL: "st-louis-blues",
    TBL: "tampa-bay-lightning",
    TOR: "toronto-maple-leafs",
    UTA: "Utah-Hockey-Club",
    VAN: "vancouver-canucks",
    VGK: "vegas-golden-knights",
    WPG: "winnipeg-jets",
    WSH: "washington-capitals",
  };

  useEffect(() => {
    // Simulate data fetching and progress update
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval); // Stop the interval at 100%
          return 100;
        }
        return prev + 5; // Increment progress by 5% for smoother updates
      });
    }, 100); // Update every 100ms for a smoother animation

    // Simulate minimum loading time of 3 seconds
    const timeout = setTimeout(() => {
      setLoading(false);
      setDataLoaded(true); // Indicate data is loaded
    }, 3000); // Minimum loading time of 3 seconds

    return () => {
      clearInterval(interval); // Cleanup interval
      clearTimeout(timeout); // Cleanup timeout
    };
  }, []);

  if (loading) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-black text-white">
        <div
          className="radial-progress text-primary"
          style={{ "--value": progress }}
          role="progressbar"
        >
          {progress}%
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-cover bg-center">
      <Header />
      <GameNavigation />
      {confettiRunning && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}

      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-4 pt-2 pb-72 mt-5 mb-5">
        {/* <h1 className="text-4xl font-bold mb-8 text-center text-white">
          Classic Mode
        </h1> */}

        <div className="flex flex-col md:flex-row justify-center items-start gap-4 mb-4 sm:w-8/12 mx-auto">
          {/* Countdown Box */}
          <div className="flex-1 w-full bg-black bg-opacity-70 rounded-lg shadow-md">
            <div className="relative text-white p-4 text-center">
              <h1 className="font-bold text-xl mb-2">
                Classic NHL Guessing Game
              </h1>
              <p className="text-base mb-2">Time left until the next player:</p>
              <span className="countdown font-mono text-2xl">
                <span style={{ "--value": timeLeft.hours }}></span>h
                <span style={{ "--value": timeLeft.minutes }}></span>m
                <span style={{ "--value": timeLeft.seconds }}></span>s
              </span>

              {/* Clues Section */}
              <div className="mt-4 flex justify-around items-start gap-4">
                {/* Draft Team Clue */}
                <div className="text-center">
                  {guesses.length < 5 && (
                    <p className="text-md text-gray-400">
                      Clue in {5 - guesses.length} guesses
                    </p>
                  )}
                  {guesses.length >= 5 && (
                    <div className="flex flex-col justify-center items-center">
                      <strong className="mb-2">Draft Team:</strong>
                      {dailyPlayer?.draft_team_abbrev ? (
                        <img
                          src={`/logos/nhl-${
                            teamLogoMapping[dailyPlayer.draft_team_abbrev]
                          }-logo.png`}
                          alt={`${dailyPlayer.draft_team_abbrev} Logo`}
                          className="w-16 h-16"
                        />
                      ) : (
                        <p className="text-md text-gray-500">N/A</p>
                      )}
                    </div>
                  )}
                </div>

                {/* Headshot Clue */}
                <div className="text-center">
                  {guesses.length < 8 && (
                    <p className="text-md text-gray-400">
                      Clue in {8 - guesses.length} guesses
                    </p>
                  )}
                  {guesses.length >= 8 && dailyPlayer?.headshot_url && (
                    <div className="flex flex-col justify-center items-center">
                      <strong className="mb-2">Headshot:</strong>
                      <img
                        src={dailyPlayer.headshot_url}
                        alt="Player Headshot"
                        className="w-16 h-16 rounded-full border border-gray-500"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Input and Controls */}
        <div className="w-full sm:w-5/6 mb-4 mx-auto flex flex-col space-y-4">
          {/* Buttons Section */}
          <div className="flex items-center justify-between bg-neutral bg-opacity-95 rounded-md shadow-md px-4 py-2 sm:w-5/12 w-10/12 mx-auto">
            {/* How to Play Button */}
            <div className="tooltip" data-tip="How to play">
              <div className="flex items-center justify-center">
                <HowToPlay />
              </div>
            </div>

            {/* Divider */}
            <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

            {/* Stats Button */}
            <div className="tooltip" data-tip="Stats">
              <div className="flex items-center justify-center">
                <Stats stats={stats} />
              </div>
            </div>

            {/* Divider */}
            <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

            {/* Update Button */}
            <div className="tooltip" data-tip="Updates">
              <div className="flex items-center justify-center">
                <Updates />
              </div>
            </div>

            {/* Divider */}
            <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

            {/* About Button */}
            <div className="tooltip" data-tip="About">
              <div className="flex items-center justify-center">
                <About />
              </div>
            </div>

            {/* Divider */}
            <div className="h-8 w-[2px] bg-gray-500 mx-2"></div>

            {/* Streak Button */}
            <div className="tooltip" data-tip="Streaks">
              <div className="flex items-center justify-center">
                <div className="relative">
                  <img
                    src="/fire.png"
                    alt="Streak"
                    className="w-10 h-10 opacity-80"
                  />
                  <span className="text-white text-2xl font-bold absolute inset-0 flex items-center justify-center">
                    {streak}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Input Section */}
          <div className="relative w-full sm:w-5/6 mx-auto">
            <form
              onSubmit={(event) => {
                event.preventDefault(); // Prevent form submission from refreshing the page
                handleSubmit(); // Call your custom submit handler
              }}
              className="relative"
            >
              <input
                type="text"
                value={guess}
                onChange={handleInputChange}
                placeholder={
                  gameWon
                    ? "You found the player! Wait for the next one."
                    : "Start typing a player's name"
                }
                className={`w-full h-12 p-3 pr-12 rounded-lg text-lg ${
                  gameWon
                    ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                    : "bg-white text-black"
                }`}
                disabled={gameWon} // Disable input if game is won
              />
              {/* Arrow Icon */}
              {!gameWon && (
                <button
                  type="submit"
                  onMouseEnter={() => setArrowSrc("/arrow-right-blue.png")}
                  onMouseLeave={() => setArrowSrc("/arrow-right.png")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  <img src={arrowSrc} alt="Submit" className="w-8 h-8" />
                </button>
              )}
            </form>

            {/* Filtered Players List */}
            {filteredPlayers.length > 0 && (
              <ul className="absolute left-0 right-0 bg-white border border-gray-300 rounded-lg mt-2 max-h-40 overflow-y-auto z-20">
                {filteredPlayers.map((player) => (
                  <li
                    key={player.player_id}
                    onClick={() => handleSelectPlayer(player)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-200 flex items-center text-black"
                  >
                    <img
                      src={player.headshot}
                      alt={player.fullName}
                      className="w-8 h-8 rounded-full mr-3"
                    />
                    <span>{player.fullName}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="text-center">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{totalGuesses}</span> people
            already found the player of the day !
          </h3>
        </div>

        {/* Combined Categories and Guesses */}
        <div className="overflow-x-auto">
          <div className="space-y-2 w-max mx-auto">
            {/* Categories Row */}
            <div className="grid grid-cols-9 gap-2 text-white text-center p-1 font-bold">
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Player
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Team
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Division
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Sweater
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Position
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Birth Country
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Age
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Overall Pick
              </div>
              <div className="w-20 h-20 flex justify-center items-center border-b-4">
                Career Points
              </div>
            </div>

            {/* Guesses */}
            {[...guesses].reverse().map((guess, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-9 gap-2 bg-gray-900 bg-opacity-60 p-2 rounded-lg"
              >
                {/* Player */}
                {guess.visibleCategories.includes("headshot") && (
                  <div className="relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg">
                    <img
                      src={guess.headshot}
                      alt={guess.name}
                      className="w-20 h-20 rounded-full"
                    />
                    <span className="absolute bottom-0 w-full text-xs font-bold text-white bg-black bg-opacity-60 px-1 rounded-b-lg">
                      {guess.name.split(" ").slice(-1)}
                    </span>
                  </div>
                )}

                {/* Team */}
                {guess.visibleCategories.includes("teamLogo") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg ${guess.teamColor}`}
                  >
                    <img
                      src={guess.teamLogo}
                      alt={`${guess.team} logo`}
                      className="w-16 h-16 rounded"
                    />
                  </div>
                )}

                {/* Conference */}
                {guess.visibleCategories.includes("conference") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.conferenceColor}`}
                  >
                    <img
                      src={`/${guess.conference}`} // Dynamic image path
                      alt={guess.conference.replace(".png", "")} // Alt text without .png
                      className="w-16 h-14"
                    />
                  </div>
                )}

                {/* Sweater */}
                {guess.visibleCategories.includes("sweaterNumber") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.sweaterColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.sweaterNumber}</span>
                    {guess.sweaterNumber > dailyPlayer.sweater_number && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.sweaterNumber < dailyPlayer.sweater_number && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Position */}
                {guess.visibleCategories.includes("position") && (
                  <div
                    className={`relative flex justify-center items-center text-center w-20 h-20 rounded-lg text-2xl font-bold ${guess.positionColor}`}
                  >
                    {guess.position}
                  </div>
                )}

                {/* Country */}
                {guess.visibleCategories.includes("country") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.countryColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.country}</span>
                  </div>
                )}

                {/* Age */}
                {guess.visibleCategories.includes("age") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.ageColor}`}
                  >
                    <span className="z-10 text-2xl">{guess.age}</span>
                    {guess.age > calculateAge(dailyPlayer.birth_date) && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.age < calculateAge(dailyPlayer.birth_date) && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Overall Pick */}
                {guess.visibleCategories.includes("overallPick") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.overallPickColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.overallPick || "N/A"}
                    </span>
                    {guess.overallPick > dailyPlayer.draft_overall_pick && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.overallPick < dailyPlayer.draft_overall_pick && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}

                {/* Career Points */}
                {guess.visibleCategories.includes("careerPoints") && (
                  <div
                    className={`relative flex flex-col justify-center items-center text-center w-20 h-20 rounded-lg font-bold ${guess.pointsColor}`}
                  >
                    <span className="z-10 text-2xl">
                      {guess.points || "N/A"}
                    </span>
                    {guess.points >
                      dailyPlayer.career_regular_season_points && (
                      <img
                        src="/down-arrows.png"
                        alt="Down arrow"
                        className="absolute bottom-1 h-6 w-6"
                      />
                    )}
                    {guess.points <
                      dailyPlayer.career_regular_season_points && (
                      <img
                        src="/up-arrows.png"
                        alt="Up arrow"
                        className="absolute top-1 h-6 w-6"
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Scroll Hint */}
        <div className="block md:hidden text-center text-white mt-2">
          <p className="text-sm">
            Swipe or scroll to the right to view more details!
          </p>
        </div>

        <div className="text-center my-10">
          <h3 className="text-sm font-bold">
            <span className="text-yellow-400">{yesterdayGuesses}</span> people
            found the player of yesterday{" "}
            {yesterdayPlayer?.full_name && (
              <span className="text-yellow-500">
                ({yesterdayPlayer.full_name})
              </span>
            )}
            !
          </h3>
        </div>

        {/* Game Won Message */}
        {gameWon && (
          <div
            ref={cardRef}
            className="bg-green-500 text-white text-center p-5 rounded-lg mt-8 max-w-md mx-auto"
          >
            <h2 className="text-3xl font-bold mb-4">Congratulations!</h2>
            <p className="text-lg">
              You guessed{" "}
              <strong>
                {dailyPlayer?.first_name} {dailyPlayer?.last_name}
              </strong>{" "}
              correctly!
            </p>
            <p className="text-lg mt-2">
              This player is for the day:{" "}
              <strong>
                {DateTime.fromISO(dailyPlayer?.date, { zone: "utc" })
                  .setZone("America/New_York")
                  .toFormat("yyyy-MM-dd")}
              </strong>
            </p>
            {/* Share Button */}
            <button
              onClick={copyToClipboard}
              className="mt-4 bg-blue-600 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700"
            >
              Share Your Win 🏒🎉
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassicGame;
