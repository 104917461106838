import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClassicGame from './pages/ClassicGame';
import HomePage from './pages/HomePage';
import RosterGuessGame from './pages/RosterGuessGame';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UnlimitedMode from './pages/UnlimitedMode';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <div
                className="relative min-h-screen bg-cover bg-center"
                style={{ backgroundImage: "url('/background.jpg')" }}
            >
                {/* Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-60"></div>

                {/* Main Content */}
                <div className="relative z-10 flex flex-col items-center justify-center min-h-screen text-white">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/classic" element={<ClassicGame />} />
                        <Route path="/unlimited" element={<UnlimitedMode />} />
                        <Route path="/roster" element={<RosterGuessGame />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        {/* <Route path="/jersey" element={<JerseyGame />} */}
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
