import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header"; // Adjust the path as necessary

const fetchSessionId = async () => {
  let sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    try {
      const response = await fetch("/api/session-id");
      if (response.ok) {
        sessionId = response.headers.get("x-session-id");
        localStorage.setItem("session_id", sessionId);
      }
    } catch (error) {
      console.error("Error fetching session ID:", error);
    }
  }
  return sessionId;
};

const GameModeCard = ({ title, description, link, image, badgeText }) => {
  return (
    <Link
      to={link}
      className="relative flex items-end justify-center bg-cover bg-center rounded-lg shadow-lg overflow-hidden h-80 w-full max-w-lg mx-auto transition-transform transform hover:scale-105"
      style={{ backgroundImage: `url(${image})` }}
    >
      {badgeText && (
        <div className="absolute top-4 right-4 badge badge-info text-neutral">
          {badgeText}
        </div>
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
      <div className="relative z-10 p-6 text-center text-white">
        <h2 className="text-4xl font-bold mb-4">{title}</h2>
        <p className="text-md text-gray-200">{description}</p>
      </div>
    </Link>
  );
};

const HomePage = () => {
  useEffect(() => {
    const initializeSession = async () => {
      await fetchSessionId();
    };
    initializeSession();
  }, []);

  return (
    <div className="fixed inset-0 bg-cover bg-center">
      <Header />
      <div className="relative max-w-4xl mx-auto h-screen overflow-y-auto rounded-lg px-8 pt-2 pb-72 mt-5">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-extrabold mb-4">
            Welcome to <span className="text-yellow-400">NHLDle</span> - The Ultimate NHL Trivia Game!
          </h1>
          <p className="text-lg font-medium sm:mx-20">
            Think you know hockey? Test your skills with NHLDle, the NHL guessing game where every day brings a new challenge.
          </p>
        </div>
  
        <h2 className="text-4xl font-bold text-center mb-8">Game Modes</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <GameModeCard
            title="Classic Mode"
            description="Guess the NHL player of the day using hints and stats. (among active players)"
            link="/classic"
            image="/classic-players.jpg"
            badgeText="Updated"
          />
          <GameModeCard
            title="Unlimited Mode"
            description="Guess the NHL player using hints and stats. (among active players)"
            link="/unlimited"
            image="/roster-players.jpg"
            badgeText="New"
          />
        </div>
  
        <div className="mt-16 max-w-4xl mx-auto bg-black bg-opacity-50 p-8">
          <h2 className="text-4xl font-bold text-center mb-6">What is NHLDle?</h2>
          <p className="text-lg mb-4">
            NHLDle is an NHL-themed guessing game designed for hockey fans. Each day, test your knowledge by identifying players, teams, and stats based on given clues. Challenge yourself, compete with friends, and learn more about your favorite NHL stars.
          </p>
          <p className="text-lg mb-4">
            Whether you're a fan of Sidney Crosby, Connor McDavid or Tomas Hertl, NHLDle offers a fun and engaging way to deepen your hockey knowledge.
          </p>
        </div>
  
        <div className="mt-12 text-center">
          <p>
            <a
              href="https://www.nhl.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-yellow-400 hover:underline"
            >
              Visit the official NHL website
            </a>{" "}
            for more hockey stats and news.
          </p>
          <p>
            <Link to="/privacy" className="text-yellow-400 hover:underline">
              View our Privacy Policy
            </Link>
          </p>
        </div>
        <p className="text-center mt-10">
          © 2024 NHLDle. All rights reserved. Powered by hockey fans, for hockey fans.
        </p>
        <div className="text-center mt-5">
        <a
          href="https://ko-fi.com/meljdev"
          target="_blank"
          rel="noopener noreferrer"
          className="text-yellow-400 font-bold hover:underline"
        >
          ☕ Buy Me a Coffee on Ko-fi!
        </a>
      </div>
      </div>
    </div>
  );  
};

export default HomePage;
