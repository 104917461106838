import React, { useState } from "react";

const Stats = ({ stats }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Stats Icon */}
      <button onClick={togglePopup} className="flex items-center justify-center">
        <img
          src="/graph.png"
          alt="Stats"
          className="h-8 w-8 cursor-pointer"
        />
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
          <div className="bg-gray-900 rounded-lg shadow-lg p-6 max-w-lg w-full text-white relative overflow-auto max-h-[80vh] m-4">
            {/* Header */}
            <h2 className="text-3xl font-bold mb-4">Game Stats</h2>

            {/* Stats Section */}
            <ul className="space-y-4 text-lg">
              <li><strong>Total Wins:</strong> {stats.totalWins}</li>
              <li><strong>Total Games:</strong> {stats.totalGames}</li>
              <li><strong>Current Streak:</strong> {stats.currentStreak}</li>
              <li><strong>Max Streak:</strong> {stats.maxStreak}</li>
              <li><strong>Average Guesses:</strong> {stats.avgGuesses}</li>
            </ul>

            {/* Close Button */}
            <button
              onClick={togglePopup}
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-200"
            >
              ✕
            </button>
            <button
              onClick={togglePopup}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Stats;
