import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header className="top-0 left-0 w-full bg-transparent z-50">
      <div className="flex items-center justify-center py-2">
        {/* Navigation Links and Logo */}
        <div className="flex items-center sm:gap-8 gap-4">
          {/* Left Navigation Link */}
          {/* <Link
            to="/classic"
            className="text-white sm:text-xl text-md font-bold hover:text-blue-600"
          >
            Classic Game
          </Link> */}

          {/* Center Logo */}
          <Link to="/">
          <img src="/nhldlelogo.png" alt="NHLDle Logo" className="h-24" />
          </Link>
          {/* Right Navigation Link */}
          {/* <Link
            to="/roster"
            className="text-white sm:text-xl text-md font-bold hover:text-blue-600"
          >
            Roster Game
          </Link> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
